.news_container {
    margin: 6px 20px 0 20px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    color: var(--tw-gray-400);
    display: flex;
}

.news_container.first {
    margin-top: 24px;
}

.news_container:last-child {
    margin-bottom: 18px;
}

.news_headline {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
    color: var(--tw-gray-600);
}

.news_date {
    min-width: 56px;
    font-weight: bold;
}

span.news_player {
    font-weight: bold;
}

.news_content {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 12px;
}

.news_summary {
    flex: 1;
}

/* New styles for grouped news by date */
.date_group {
    margin: 20px 20px 0 20px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
}

.date_group.first {
    margin-top: 24px;
}

.date_header {
    color: var(--tw-gray-350);
    margin: 6px 0 10px 10px;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid var(--tw-gray-300);
}

.news_container_grouped {
    margin: 6px 0 10px 10px;
    text-align: left;
    color: var(--tw-gray-400);
}