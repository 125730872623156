.player_header_container {
    margin-top: 5px;
}

.section_spacer:not(:first-child) {
    margin-top: 24px;
}

.latest_news_container {
    margin: 6px 20px 0 20px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    color: var(--tw-gray-400);
    display: flex;
}

.latest_news_container.first {
    margin-top: 24px;
}

.latest_news_container:last-child {
    margin-bottom: 18px;
}

.latest_news_headline {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
    color: var(--tw-gray-600);
}

.latest_news_date {
    min-width: 56px;
    font-weight: bold;
}

.latest_news_content {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 12px;
}

.scouting_roundup_container {
    margin: 18px 20px 18px 20px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    color: var(--tw-gray-400);
}
.scouting_roundup_container.no_data {
    text-align: center;
}

.scouting_roundup {
    display: flex;
    flex-direction: column;
}

.scouting_projection_report {
    display: flex;
    align-items: stretch;
    padding: 0 4px;
}

.scouting_roundup_container.narrow_layout .scouting_projection_report {
    justify-content: center;
}

.scouting_roundup_section_content {
    margin-top: 8px;
}

.scouting_roundup_container.narrow_layout .scouting_roundup_section_content {
    margin-top: 6px;
    margin-bottom: 6px;
}

.scouting_projection_consensus_option {
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
}

.scouting_projection_control {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 8px 0 8px;
    font-size: 20px;
}

.scouting_projection_control.can_override {
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
    border-radius: 3px;
}

.scouting_projection_option_container {
    padding: 4px;
    font-size: 12px;
}

.scouting_projection_override {
    margin: 0.2em 0;
    color: var(--ch-red-400);
}

.scouting_projection {
    width: 3em;
    height: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
}

.consensus_scouting_projection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: 0 0.5px 2px 0.5px rgba(9, 30, 66, 0.25);
    border-radius: 3px;
    color: white;
}

.scouting_projection_control.can_override .consensus_scouting_projection {
    box-shadow: none;
}

.scouting_projection_distribution {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scouting_projection_distribution_label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
    color: var(--tw-gray-500);
}

.scouting_projection_distribution_stack {
    font-weight: bold;
    margin-left: 10px;
}

.scouting_projection_distribution_stack_segment {
    display: flex;
    min-height: 24px;
}

.scouting_projection_distribution_stack_segment_bar {
    width: 2.5em;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
}

.scouting_projection_distribution_stack_segment_bar.top {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.scouting_projection_distribution_stack_segment_bar.bottom {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.consensus_attributes {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.attributes_list {
    margin-top: 0;
}

.scouting_roundup_container.narrow_layout .consensus_attributes {
    margin-top: initial;
    flex-direction: column;
}

.scouting_roundup_section {
    text-align: left;
}

.scouting_roundup_section_header {
    font-weight: bold;
    text-align: center;
    color: var(--tw-gray-500);
}

.scouting_projection_distribution_stack_segment_scouts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px 0;
}

.scouting_projection_distribution_stack_segment_scout {
    font-size: 10px;
    color: var(--tw-gray-325);
    padding-left: 4px;
    margin-top: 2px;
    white-space: nowrap;
}

.scouting_last_seen {
    font-size: 12px;
    font-style: italic;
    text-align: right;
    margin-top: 12px;
}

.combine_report {
    color: var(--tw-gray-400);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
}

.combine_report_section {
    width: 100%;
    max-width: 600px;
}

.combine_report_notes {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    margin: 16px;
    text-align: left;
}

.combine_report_metrics {
    display: flex;
    align-items: center;
    margin: 16px 0;
}

.combine_report_metrics_label,
.combine_report_component_label {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    margin-right: 4px;
    color: var(--tw-gray-500);
}

.combine_report_metrics_value {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.combine_report_metrics_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 16px;
}

.combine_report_components_section {
    flex-grow: 1;
}

.total_athleticism_rank {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    color: var(--tw-slate-500);
    margin-left: 6px;
}

.combine_report_component_label {
    text-align: right;
    width: 5em;
}

.combine_report_components {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
}

.combine_report_component_bar_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-style: normal;
    flex-grow: 1;
    display: flex;
    font-size: 12px;
    margin: 4px 0 4px 4px;
}

.combine_report_metric {
    display: flex;
    align-items: flex-end;
    margin: 6px 16px;
}

.combine_report_metric_label {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    color: var(--tw-gray-500);
    margin-right: 4px;
    text-align: right;
    width: 5em;
}

.combine_report_metric_distribution_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-style: normal;
    flex-grow: 1;
    display: flex;
    font-size: 12px;
    margin: 4px 0 4px 4px;
}

.component_container {
    width: 100%;
    display: flex;
}

.no_data {
    margin-bottom: 3px;
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
